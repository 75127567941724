import { Options } from "vue-class-component";
import BaseTableInterface from "@/mixins/BaseTableInterface";
import BaseTableMixins from "@/mixins/BaseTable";
import SelectLevel from "@/modules/select-level/select-level.vue";
import DraggableListview from "@/modules/draggable-listview/draggable-listview.vue";
import InfluenceTableItem from "@/entity/aftersale/ticket/InfluenceTableItem";
import API from "@/api/aftersale/ticket/influence";

@Options({
    name: "app-aftersale-ticket-influence",
    components: {
        "app-select-level": SelectLevel,
        "app-draggable-listview": DraggableListview,
    },
})
export default class Influence extends BaseTableMixins<InfluenceTableItem> implements Partial<BaseTableInterface<InfluenceTableItem>> {
    public created(): void {
        this.setInterface(this);
        this.tableRequestItem.lockItem = this.getLockItem();
        this.editTableItem = new InfluenceTableItem();
        this.editModalItem = new InfluenceTableItem();
    }

    //锁定列数据实体
    public getLockItem(): any {
        return {
            influenceParentId: {
                type: "router",
                name: "influence_id",
                title: "上级影响范围",
                parent: -1,
                value: null,
                openFn: this.influenceParentIdLevelModalOpen,
            },
        };
    }

    public activated(): void {
        this.getList(1);
    }

    //后端API
    public getApi(): any {
        return API;
    }

    // //列表回调
    // public listCallback(items: Array<InfluenceTableItem>): void {
    //     return;
    // }

    //添加弹窗配置
    public addModalSetting(): void {
        this.editTableItem = new InfluenceTableItem();
        this.editModalItem = new InfluenceTableItem();
        this.editModalItem.influenceStatus = 1;
        this.editModalTitle = "添加影响范围";
        this.editModalSubmitButtonName = "添加";
    }
    //添加提交检测
    public addSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.influenceName)) return "影响范围名称不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.influenceStatus)) return "影响范围状态不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.influenceParentId)) return "上级影响范围不能为空！";
        return null;
    }
    // //添加回调
    // public addModalCallback(res: any): void {
    //     return;
    // }

    //修改弹窗配置
    public modModalSetting(): void {
        this.editModalTitle = "修改影响范围 (" + this.editModalItem.influenceName + ")";
        this.editModalSubmitButtonName = "修改";
    }
    //修改提交检测
    public modSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.influenceId)) return "影响范围ID不能为空！";
        return this.addSubmitChecking();
    }
    // //修改回调
    // public modModalCallback(res: any): void {
    //     return;
    // }

    //删除提示框配置
    public delAlertConfig(): any {
        return {
            type: "okAndNo",
            title: "删除影响范围",
            message: "影响范围: " + this.editModalItem.influenceName + "<br>请确认删除？",
        };
    }
    // //删除提示框配置
    // public delAlertSetting(): void {
    //     return;
    // }
    //删除提示框提交检测
    public delSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.influenceId)) return "影响范围ID不能为空！";
        return null;
    }
    // //删除回调
    // public delAlertCallback(res: any): void {
    //     return;
    // }

    //状态提示框配置
    public statusAlertConfig(): any {
        return {
            title: "影响范围状态",
            message: "影响范围: " + this.editModalItem.influenceName + "<br>请确认修改状态？",
            submitButtonName: this.editModalItem.influenceStatus === 1 ? "启用" : "禁用",
        };
    }
    //状态切换请求配置
    public toggleStatusSetting(): void {
        this.editModalItem.influenceStatus = this.editModalItem.influenceStatus === 1 ? 2 : 1;
    }
    //状态切换提交检测
    public toggleStatusSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.influenceId)) return "影响范围ID不能为空！";
        return null;
    }
    // //状态切换回调
    // public toggleStatusCallback(): void {
    //     return;
    // }

    //排序弹窗配置
    public sortModalSetting(): void {
        this.editSortBaseTitle = "影响范围排序";
    }
    //排序提交检测
    public sortSubmitChecking(): string {
        if (this.editModalItem.influenceParentId < -1) {
            this.toast.error("上级ID不能小余-1");
            return;
        }
        return null;
    }

    //influenceParentId搜索字段
    private influenceParentIdLevelSearchField: any = [
        { field: "influence_name", name: "名称" },
        { field: "influence_remark", name: "说明" },
    ];
    //influenceParentId层级选择器打开
    public influenceParentIdLevelModalOpen() {
        (this.$refs.influenceParentIdLevel as any).openModal();
    }
    //influenceParentId层级选择器提交
    public async influenceParentIdLevelFunction(item: any): Promise<void> {
        const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem().influenceParentId : item;
        this.tableRequestItem.lockItem.influenceParentId.value = curItem.value;
        this.tableRequestItem.lockItem.influenceParentId.title = curItem.title;
        this.tableRequestItem.lockItem.influenceParentId.parent = curItem.parent;
        await this.getList(1);
        return Promise.resolve();
    }

    get influenceParentIdSelectApi() {
        return {
            api: (query: any) => API.idSelect(query),
            parent: "influence_parent_id",
            status: "influence_status",
            name: "influence_name",
            id: "influence_id",
            cid: this.editModalItem.influenceParentId,
            mid: this.editModalItem.influenceId,
            extend: true,
        };
    }
}
